/*
html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}
*/

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#layout-loading, #layout-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#header .avatar {
  height: 28px;
  cursor: pointer;
}

#header .avatar img {
  height: 100%;
  border-radius: 100px;
}

.avatar-popover {
  background: white;
  border: 1px solid black;
  padding: 1em;
  border-radius: 4px;
}
